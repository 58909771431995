<template>
  <b-nav-item-dropdown
    v-if="employeeData"
    id="dropdown-user"
    right
    toggle-class="d-flex-center justify-content-start gap-1 dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-none d-sm-flex user-nav">
        <p
          class="user-name font-weight-bolder mb-0"
          :class="isMobileView ? 'text-truncate' : ''"
        >
          {{ `${['xs'].includes(appBreakPoint) ? '' : employeeData.lastName} ${employeeData.firstName}` }}
        </p>
        <span
          style="color: #ff9f43 !important;"
          class="user-status"
        >{{ employeeData.type }}</span>
      </div>
      <b-avatar
        size="32"
        :src="employeeData.avatar"
        :variant="`light-${resolveEmployeeTypeVariant(employeeData.type)}`"
        :text="avatarText(`${employeeData.lastName} ${employeeData.firstName}`)"
        badge
        class="badge-minimal shadow"
        badge-variant="success"
      />
    </template>

    <div class="d-flex flex-column align-items-center d-sm-none user-nav border-bottom pb-25">
      <p
        class="user-name font-weight-bolder mb-0"
        :class="isMobileView ? 'text-truncate' : ''"
      >
        {{ `${['xs'].includes(appBreakPoint) ? '' : employeeData.lastName} ${employeeData.firstName}` }}
      </p>
      <span
        style="color: #ff9f43 !important;"
        class="user-status"
      >{{ employeeData.type }}</span>
    </div>

    <b-dropdown-item :to="{ name: 'account-settings' }">
      <HStack class="align-items-center justify-content-start">
        <feather-icon
          size="16"
          icon="SettingsIcon"
        />
        <span>{{ $t('account') }}</span>
      </HStack>
    </b-dropdown-item>

    <b-dropdown-item :to="{ name: 'account-settings', hash: '#change-password'}">
      <HStack class="align-items-center justify-content-start">
        <feather-icon
          icon="LockIcon"
          size="18"
        />
        <span>{{ $t('changePassword') }}</span>
      </HStack>
    </b-dropdown-item>

    <b-dropdown-item :to="{ name: 'account-settings', hash: '#change-payment-password'}">
      <HStack class="align-items-center justify-content-start">
        <IAmIcon
          icon="keyOutline"
          size="18"
        />
        <span>{{ $t('myAccount.paymentPassword.title') }}</span>
      </HStack>
    </b-dropdown-item>

    <b-dropdown-item :to="{ name: 'account-settings', hash: '#mfa'}">
      <HStack class="align-items-center justify-content-start">
        <feather-icon
          icon="KeyIcon"
          size="18"
        />
        <span>{{ $t('myAccount.mfa.title') }}</span>
      </HStack>
    </b-dropdown-item>

    <b-dropdown-item @click="showModalConfirmLogout">
      <HStack class="align-items-center justify-content-start">
        <feather-icon
          size="16"
          icon="LogOutIcon"
        />
        <span>{{ $t('logout') }}</span>
      </HStack>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BAvatar,
} from 'bootstrap-vue'

import { getUserData } from '@/api/auth/utils'
import useJwt from '@/api/auth/jwt/useJwt'
import useEmployeeHandle from '@/views/apps/employee/useEmployeeHandle'
import { useRouter } from '@/@core/utils/utils'
import store from '@/store'

import { avatarText } from '@core/utils/filter'

import useToast from '@useToast'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
  },
  setup() {
    const { userData, employeeData } = getUserData()

    const { resolveEmployeeTypeIcon, resolveEmployeeTypeVariant } = useEmployeeHandle()
    const { toastSuccess } = useToast()
    const { router } = useRouter()
    // Methods
    function logout() {
      return useJwt.logout()
        .then(() => {
          store.dispatch('userStore/clearAbility')
          toastSuccess('messagesList.logoutSuccessful')
        })
        .catch(err => {
          console.error({ err })
        })
        .finally(() => {
          useJwt.clearStorage()
          // Redirect to login page
          router.push({ name: 'auth-login' })
        })
    }
    function showModalConfirmLogout() {
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.confirmLogout'), {
          title: this.$t('modal.logout'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$bvModal.show('modal-api-loading')
            logout().finally(() => {
              this.$bvModal.hide('modal-api-loading')
            })
          }
        })
    }

    return {
      userData,
      employeeData,
      avatarText,
      resolveEmployeeTypeIcon,
      resolveEmployeeTypeVariant,
      // Methods
      showModalConfirmLogout,
    }
  },
}
</script>

<style lang="scss" scoped>
#dropdown-user ::v-deep {
  .dropdown-menu.dropdown-menu-right.show {
    margin-top: 0px;
  }
}
</style>
