var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.inputType, _vm._b({
    ref: "refInput",
    tag: "component",
    attrs: {
      "value": _vm.text
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:text', val);
      }
    }
  }, 'component', [_vm.$attrs], false));
}
var staticRenderFns = []

export { render, staticRenderFns }