import { computed } from '@vue/composition-api'

import { getUserData } from '@/api/auth/utils'
import env from '@/libs/env'
import { arrayToObject } from '@/@core/utils/utils'

// SECTION NEW
// store
export const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'
export const INCORRECT_INVOICE_APP_STORE_MODULE_NAME = 'app-incorrect-invoice'

// default variable
export const DEFAULT_VAT_RATE = 'EIGHT_PERCENT' // 8
export const DEFAULT_UNIT = 'Vé'
export const PAYMENT_METHOD_DEFAULT = 'CK_AND_TM'
export const DIFFERENT_FREE = 55000 // miễn phí chênh
export const DIFFERENT_RATE = 0.05 // tỷ lệ thanh toán khi chênh phí (mặc định 5%)

const isADMF1 = computed(() => {
  const { employeeData } = getUserData()
  return employeeData?.type === 'ADM' && employeeData?.agency?.id === env.mamaId
})

// portal
export const URL_DEEP_LINK = {
  CERTIFICATE_MANAGE: '/certificate-mng', // quản lý chứng thư số
  // CERTIFICATE_MANAGE: '/certificate-mng', // đăng ký phát hành
  DECLARATION: '/dec-form', // Tờ khai
  AUTHORIZATION_DECLARATION: '/dec-form-del', // Tờ khai uỷ nhiệm
  INVOICE_TEMPLATE_MANAGE: '/invoice-template-mng', // Mẫu hoá đơn
  INVOICE_RELEASE_BATCHES: '/invoice-release-batches', // Quản lý lô phát hành HĐ
}

// Path
export const INVOICE_ADDITIONAL_LIST_PATH_NAME = 'apps-invoiceList-additional'
export const INVOICE_LIST_PATH_NAME = 'apps-invoiceList'
export const INVOICE_REQUEST_LIST_PATH_NAME = 'apps-invoiceRequest-list'

// filter
export const INVOICE_STATUS_OPTIONS = [
  { label: 'NEW', value: 'NEW' },
  { label: 'SIGNED', value: 'SIGNED' },
  { label: 'ADJUSTMENT', value: 'ADJUSTMENT' },
  { label: 'ADJUSTED', value: 'ADJUSTED' },
  { label: 'REPLACEMENT', value: 'REPLACEMENT' },
  { label: 'REPLACED', value: 'REPLACED' },
  { label: 'CANCELLED', value: 'CANCELLED' },
]

export const INVOICE_STATUS = arrayToObject(INVOICE_STATUS_OPTIONS)

export const RELEASE_STATUS_OPTIONS = [
  { label: 'NEW', value: 'NEW' },
  { label: 'SENT', value: 'SENT' },
  { label: 'CQT_ISSUED_CODE', value: 'CQT_ISSUED_CODE' },
  { label: 'CQT_REJECT_ISSUE_CODE', value: 'CQT_REJECT_ISSUE_CODE' },
]
export const RELEASE_STATUS = arrayToObject(RELEASE_STATUS_OPTIONS)

export const ADDITIONAL_AMOUNT_STATUS = [
  { label: 'UNPAID', value: 'UNPAID' },
  { label: 'PAID', value: 'PAID' },
]
export const ADDITIONAL_AMOUNT_STATUS_VALUES = arrayToObject(ADDITIONAL_AMOUNT_STATUS)

export const SIGN_STATUS = [
  { label: 'UNSIGNED', value: 'UNSIGNED' },
  { label: 'SIGNED', value: 'SIGNED' },
]

export const SIGN_TYPE_OPTIONS = [
  { label: 'FILE', value: 'FILE' }, // file mềm
  { label: 'USB', value: 'USB' }, // USB
]

export const SIGN_TYPE = arrayToObject(SIGN_TYPE_OPTIONS)

export const BATCH_TYPE = {
  GENERATE_INVOICE_NUMBER_AT_START: 'GENERATE_INVOICE_NUMBER_AT_START',
  GENERATE_INVOICE_NUMBER_AT_RELEASE: 'GENERATE_INVOICE_NUMBER_AT_RELEASE',
}

// ẩn hiện các nút chức năng
export const BUTTON_ACTION_PERMISSIONS = {
  // EDIT, SIGN, SEND_EMAIL, RELEASE, VIEW_PDF, ADJUST, REPLACE, DUPLICATE, CANCEL
  NEW: {
    NEW: {
      EDIT: true,
      SIGN: true,
      VIEW_PDF: true,
      SEND_EMAIL: true,
      DUPLICATE: true,
    },
    SENT: {},
    CQT_ISSUED_CODE: {},
    CQT_REJECT_ISSUE_CODE: {},
  },
  SIGNED: {
    NEW: {
      EDIT: true,
      RELEASE: true,
      VIEW_PDF: true,
      SEND_EMAIL: true,
      DUPLICATE: true,
    },
    SENT: {
      VIEW_PDF: true,
      SEND_EMAIL: true,
      DUPLICATE: true,
    },
    CQT_ISSUED_CODE: {
      VIEW_PDF: true,
      SEND_EMAIL: true,
      DUPLICATE: true,
      ADJUSTMENT: true,
      REPLACEMENT: true,
      CANCEL: true,
    },
    CQT_REJECT_ISSUE_CODE: {
      VIEW_PDF: true,
      SEND_EMAIL: true,
      DUPLICATE: true,
    },
  },
  ADJUSTMENT: {
    NEW: {
      EDIT: true,
      RELEASE: true,
      VIEW_PDF: true,
      SEND_EMAIL: true,
      DUPLICATE: true,
      SIGN: true,
    },
    SENT: {
      VIEW_PDF: true,
      SEND_EMAIL: true,
      DUPLICATE: true,
    },
    CQT_ISSUED_CODE: {
      VIEW_PDF: true,
      SEND_EMAIL: true,
      DUPLICATE: true,
      ADJUSTMENT: true,
    },
    CQT_REJECT_ISSUE_CODE: {
      VIEW_PDF: true,
      SEND_EMAIL: true,
      DUPLICATE: true,
    },
  },
  ADJUSTED: {
    NEW: {},
    SENT: {},
    CQT_ISSUED_CODE: {
      VIEW_PDF: true,
      SEND_EMAIL: true,
      DUPLICATE: true,
    },
    CQT_REJECT_ISSUE_CODE: {},
  },
  REPLACEMENT: {
    NEW: {
      EDIT: true,
      RELEASE: true,
      VIEW_PDF: true,
      SEND_EMAIL: true,
      DUPLICATE: true,
    },
    SENT: {
      VIEW_PDF: true,
      SEND_EMAIL: true,
      DUPLICATE: true,
    },
    CQT_ISSUED_CODE: {
      VIEW_PDF: true,
      SEND_EMAIL: true,
      DUPLICATE: true,
      ADJUSTMENT: true,
    },
    CQT_REJECT_ISSUE_CODE: {
      VIEW_PDF: true,
      SEND_EMAIL: true,
      DUPLICATE: true,
    },
  },
  REPLACED: {
    NEW: {},
    SENT: {},
    CQT_ISSUED_CODE: {
      VIEW_PDF: true,
      SEND_EMAIL: true,
      DUPLICATE: true,
    },
    CQT_REJECT_ISSUE_CODE: {},
  },
  CANCELLED: {
    NEW: {},
    SENT: {},
    CQT_ISSUED_CODE: {
      VIEW_PDF: true,
      SEND_EMAIL: true,
      DUPLICATE: true,
    },
    CQT_REJECT_ISSUE_CODE: {},
  },
}

// ẩn hiện các nút chức năng của thông báo sai sót
export const BUTTON_ACTION_PERMISSIONS_INCORRECT_INVOICE = {
  NEW: {
    NOT_YET_SENT: {
      EDIT: true,
      SIGN: true,
      VIEW_PDF: true,
      SEND_EMAIL: true,
    },
    SENT: {},
  },
  SIGNED: {
    NOT_YET_SENT: {
      SIGN: true,
      SEND_TAX_AUTHORITY: true,
      VIEW_PDF: true,
      SEND_EMAIL: true,
    },
    SENT: {
      VIEW_PDF: true,
      SEND_EMAIL: true,
    },
    IINO_VALID: {
      VIEW_PDF: true,
      SEND_EMAIL: true,
    },
    IINO_INVALID: {
      VIEW_PDF: true,
      SEND_EMAIL: true,
    },
    IINO_RECEIVED_AND_RETURNING_RESULTS: {
      VIEW_PDF: true,
      SEND_EMAIL: true,
    },
  },
}

export const SEND_MAIL_STATUS = [
  { label: 'WAITING', value: 'WAITING' },
  { label: 'SENT', value: 'SENT' },
]

export const ADJUSTMENT_TYPE_OPTIONS = [
  { label: 'INFORMATIONAL_ADJUSTMENT', value: 'INFORMATIONAL_ADJUSTMENT' },
  { label: 'INCREASE_ADJUSTMENT', value: 'INCREASE_ADJUSTMENT' },
  { label: 'DECREASE_ADJUSTMENT', value: 'DECREASE_ADJUSTMENT' },
]

export const ADJUSTMENT_TYPE = arrayToObject(ADJUSTMENT_TYPE_OPTIONS)
// export const DISCOUNT_TYPES = [
//   { label: 'NO_DISCOUNT', value: 'Không có chiết khấu' },
//   { label: 'BY_ITEM', value: 'Theo mặt hàng' },
//   { label: 'BY_TOTAL_INVOICE', value: 'Theo tổng giá trị hoá đơn' },
// ]
export const TAX_RATE_TYPES = {
  MULTIPLE: 'MULTIPLE',
  SINGLE: 'SINGLE',
}

export const TAX_TYPES = [
  { label: 'ZERO_PERCENT', value: 0 },
  { label: 'FIVE_PERCENT', value: 5 },
  { label: 'EIGHT_PERCENT', value: 8 },
  { label: 'TEN_PERCENT', value: 10 },
  { label: 'NON_TAXABLE', value: 0 }, // Không chịu thuế
  { label: 'NON_DECLARED', value: 0 }, // Không kê khai nộp thuế
  { label: 'OTHER', value: 0 }, // Khác
]

export const TAX_TYPES_OBJECT = arrayToObject(TAX_TYPES, 'label', 'value')

export const GOODS_SERVICE_TYPES = {
  GOODS_SERVICE: 'GOODS_SERVICE', // Hàng hoá dịch vụ
  PROMOTION: 'PROMOTION', // Khuyến mại
  TRADE_DISCOUNT: 'TRADE_DISCOUNT', // Chiết khấu thương mại (theo dòng)
  NOTE: 'NOTE', // Ghi chú, diễn giải
}

export const PAYMENT_METHOD_LIST = [
  { label: 'TM', value: 'TM' },
  { label: 'CK', value: 'CK' },
  { label: 'TM/CK', value: 'CK_AND_TM' },
]

export const CUSTOMER_TYPE_OPTIONS = [
  { label: 'KL', value: 'KHACH_LE' },
  { label: 'CTV', value: 'CTV' },
]

export const FLIGHT_TYPE = {
  Domestic: 'Vé máy bay nội địa',
  Inbound: 'Vé máy bay quốc tế',
  Other: 'Khác',
}

export const INVOICE_LIST_COLUMNS = [
  {
    key: 'checkbox', stickyColumn: true, tdClass: 'bg-white width-30', default: true, fixed: true,
  },
  {
    key: 'symbol', stickyColumn: false, tdClass: 'bg-white', default: true,
  }, // Mẫu số/ ký hiệu
  {
    key: 'invNumber', stickyColumn: true, tdClass: 'bg-white', class: 'position-left-45', default: true, defaultForAdditional: true,
  }, // Số hoá đơn
  { key: 'createdAt', default: true }, // ngày lập
  { key: 'releasedInvCode' }, // Mã CQT
  { key: 'buyerUnitCode' }, // Mã khách hàng
  { key: 'buyerName', default: true }, // Khách hàng (tên người mua)
  { key: 'buyerUnitName', default: true }, // Khách hàng (tên công ty)
  { key: 'buyerTaxCode' }, // Mã số thuế
  { key: 'buyerAddress' }, // địa chỉ
  { key: 'buyerEmail' }, // email
  { key: 'buyerPhoneNumber' }, // số điện thoại
  { key: 'paymentMethod' }, // hình thức thanh toán
  { key: 'bankAccountNumber' }, // số tk ngân hàng
  { key: 'bankName' }, // tên ngân hàng
  { key: 'totalAmount', default: true }, // Tổng tiền
  { key: 'ticketNumberInvoiceItem', defaultForAdditional: true }, // Số vé tương ứng từng invoice item
  { key: 'bookingCodeInvoiceItem', defaultForAdditional: true }, // Mã đặt chỗ tương ứng từng invoice item
  { key: 'nameInvoiceItem', defaultForAdditional: true }, // Hành trình tương ứng từng invoice item
  { key: 'buyPrice', defaultForAdditional: true }, // Giá mua
  { key: 'sellPrice', defaultForAdditional: true }, // Giá bán
  { key: 'differentPrice', defaultForAdditional: true }, // Chênh lệch giá bán - giá mua
  { key: 'totalAmountAdditional', defaultForAdditional: true }, // Phí chênh => cần thanh toán
  { key: 'invoiceStatus', default: true, defaultForAdditional: true }, // Trạng thái hoá đơn
  { key: 'releaseStatus', default: true, defaultForAdditional: true }, // Trạng thái phát hành
  { key: 'signStatus' }, // Trạng thái ký hoá đơn
  { key: 'sendMailStatus', default: true }, // Trạng thái gửi email
  { key: 'requestInvoiceStatus', default: true, defaultForRequest: true }, // Trạng thái yêu cầu
  { key: 'additionalAmountStatus', defaultForAdditional: true }, // Trạng thái thanh toán phí xuất chênh
  { key: 'agencyCustomerReceive', defaultForAdditional: true }, // đại lý/khách hàng
  { key: 'referenceCode', default: true }, // Mã tra cứu
  { key: 'tags', default: true }, // Nhãn
  {
    key: 'action', stickyColumn: true, tdClass: 'bg-white', class: 'position-right-0', default: true, fixed: true,
  }, // Thao tác
]

export const INVOICE_LIST_FIXED_COLUMNS = INVOICE_LIST_COLUMNS.filter(col => col.fixed).map(col => col.key)

export const CREATED_TYPE_OPTIONS = [
  { value: 'INVOICE', label: 'INVOICE' },
  { value: 'REQUEST', label: 'REQUEST' },
  { value: 'RELEASE', label: 'RELEASE' },
]

export const CREATED_TYPE = arrayToObject(CREATED_TYPE_OPTIONS)

export const CREATED_TYPE_STATUS_OPTIONS = [
  { value: 'PENDING', label: 'PENDING' },
  { value: 'ACCEPTED', label: 'ACCEPTED' },
  // { value: 'REJECTED', label: 'REJECTED' },
]
export const CREATED_TYPE_STATUS = arrayToObject(CREATED_TYPE_STATUS_OPTIONS)

export const REQUEST_RELEASE_STATUS_OPTIONS = [
  { value: 'MIGHT_SENT', label: 'MIGHT_SENT' },
  { value: 'PENDING', label: 'PENDING' },
  { value: 'ACCEPTED', label: 'ACCEPTED' },
  // { value: 'REJECTED', label: 'REJECTED' },
]
export const REQUEST_RELEASE_STATUS = arrayToObject(REQUEST_RELEASE_STATUS_OPTIONS)

export const INVOICE_REQUEST_STATUS_PERMISSIONS = {
  EDIT: 'EDIT',
  VIEW_PDF: 'VIEW_PDF',
  REQUEST_RELEASE: 'REQUEST_RELEASE',
  RELEASE: 'RELEASE',
  DUPLICATE: 'DUPLICATE',
  SEND_EMAIL: 'SEND_EMAIL',
  CANCEL: 'CANCEL',
  ADJUSTMENT: 'ADJUSTMENT',
  REPLACEMENT: 'REPLACEMENT',
}
// Trạng thái yêu cầu => đối với RELEASE invoice
export const INVOICE_REQUEST_STATUS_OPTIONS = [
  {
    label: 'ALL',
    value: null,
    variant: 'light-dark',
  },
  {
    label: 'NOT_REQUEST',
    value: 'NOT_REQUEST',
    variant: 'light-secondary',
    invoiceStatus: INVOICE_STATUS.NEW,
    requestReleaseStatus: null,
    permissions: {
      F1: [
        INVOICE_REQUEST_STATUS_PERMISSIONS.VIEW_PDF,
        INVOICE_REQUEST_STATUS_PERMISSIONS.SEND_EMAIL],
      F2: [
        INVOICE_REQUEST_STATUS_PERMISSIONS.EDIT,
        INVOICE_REQUEST_STATUS_PERMISSIONS.VIEW_PDF,
        INVOICE_REQUEST_STATUS_PERMISSIONS.REQUEST_RELEASE,
        INVOICE_REQUEST_STATUS_PERMISSIONS.DUPLICATE,
        INVOICE_REQUEST_STATUS_PERMISSIONS.SEND_EMAIL,
      ],
    },
  },
  {
    label: 'REQUESTED',
    value: 'REQUESTED',
    variant: 'light-warning',
    invoiceStatus: INVOICE_STATUS.NEW,
    requestReleaseStatus: REQUEST_RELEASE_STATUS.PENDING,
    permissions: {
      F1: [
        INVOICE_REQUEST_STATUS_PERMISSIONS.VIEW_PDF,
        INVOICE_REQUEST_STATUS_PERMISSIONS.RELEASE,
        INVOICE_REQUEST_STATUS_PERMISSIONS.SEND_EMAIL,
      ],
      F2: [
        INVOICE_REQUEST_STATUS_PERMISSIONS.VIEW_PDF,
        INVOICE_REQUEST_STATUS_PERMISSIONS.DUPLICATE,
        INVOICE_REQUEST_STATUS_PERMISSIONS.SEND_EMAIL,
      ],
    },
  },
  {
    label: 'SENT_AUTHORITY',
    value: 'SENT_AUTHORITY',
    variant: 'light-info',
    releaseStatus: RELEASE_STATUS.SENT,
    // requestReleaseStatus: REQUEST_RELEASE_STATUS.ACCEPTED,
    permissions: {
      F1: [
        INVOICE_REQUEST_STATUS_PERMISSIONS.VIEW_PDF,
        INVOICE_REQUEST_STATUS_PERMISSIONS.SEND_EMAIL,
      ],
      F2: [
        INVOICE_REQUEST_STATUS_PERMISSIONS.VIEW_PDF,
        INVOICE_REQUEST_STATUS_PERMISSIONS.DUPLICATE,
        INVOICE_REQUEST_STATUS_PERMISSIONS.SEND_EMAIL,
      ],
    },
  },
  {
    label: 'CQT_ISSUED_CODE',
    value: 'CQT_ISSUED_CODE',
    variant: 'success',
    releaseStatus: RELEASE_STATUS.CQT_ISSUED_CODE,
    // requestReleaseStatus: REQUEST_RELEASE_STATUS.ACCEPTED,
    permissions: {
      F1: [
        INVOICE_REQUEST_STATUS_PERMISSIONS.VIEW_PDF,
        INVOICE_REQUEST_STATUS_PERMISSIONS.CANCEL,
        INVOICE_REQUEST_STATUS_PERMISSIONS.ADJUSTMENT,
        INVOICE_REQUEST_STATUS_PERMISSIONS.REPLACEMENT,
        INVOICE_REQUEST_STATUS_PERMISSIONS.SEND_EMAIL,
      ],
      F2: [
        INVOICE_REQUEST_STATUS_PERMISSIONS.VIEW_PDF,
        INVOICE_REQUEST_STATUS_PERMISSIONS.DUPLICATE,
        INVOICE_REQUEST_STATUS_PERMISSIONS.SEND_EMAIL,
      ],
    },
  },
  {
    label: 'CQT_REJECT_ISSUE_CODE',
    value: 'CQT_REJECT_ISSUE_CODE',
    variant: 'light-danger',
    releaseStatus: RELEASE_STATUS.CQT_REJECT_ISSUE_CODE,
    // requestReleaseStatus: REQUEST_RELEASE_STATUS.ACCEPTED,
    permissions: {
      F1: [
        INVOICE_REQUEST_STATUS_PERMISSIONS.VIEW_PDF,
        INVOICE_REQUEST_STATUS_PERMISSIONS.SEND_EMAIL,
      ],
      F2: [
        INVOICE_REQUEST_STATUS_PERMISSIONS.VIEW_PDF,
        INVOICE_REQUEST_STATUS_PERMISSIONS.DUPLICATE,
        INVOICE_REQUEST_STATUS_PERMISSIONS.SEND_EMAIL,
      ],
    },
  },
  {
    label: 'CANCELLED',
    value: 'CANCELLED',
    variant: 'danger',
    invoiceStatus: INVOICE_STATUS.CANCELLED,
    // requestReleaseStatus: REQUEST_RELEASE_STATUS.ACCEPTED,
    permissions: {
      F1: [
        INVOICE_REQUEST_STATUS_PERMISSIONS.VIEW_PDF,
        INVOICE_REQUEST_STATUS_PERMISSIONS.SEND_EMAIL,
      ],
      F2: [
        INVOICE_REQUEST_STATUS_PERMISSIONS.VIEW_PDF,
        INVOICE_REQUEST_STATUS_PERMISSIONS.DUPLICATE,
        INVOICE_REQUEST_STATUS_PERMISSIONS.SEND_EMAIL,
      ],
    },
  },
  {
    label: 'ADJUSTED',
    value: 'ADJUSTED',
    variant: 'info',
    invoiceStatus: INVOICE_STATUS.ADJUSTED,
    // requestReleaseStatus: REQUEST_RELEASE_STATUS.ACCEPTED,
    permissions: {
      F1: [
        INVOICE_REQUEST_STATUS_PERMISSIONS.VIEW_PDF,
        INVOICE_REQUEST_STATUS_PERMISSIONS.SEND_EMAIL,
      ],
      F2: [
        INVOICE_REQUEST_STATUS_PERMISSIONS.VIEW_PDF,
        INVOICE_REQUEST_STATUS_PERMISSIONS.DUPLICATE,
        INVOICE_REQUEST_STATUS_PERMISSIONS.SEND_EMAIL,
      ],
    },
  },
  {
    label: 'ADJUSTMENT',
    value: 'ADJUSTMENT',
    variant: 'light-info',
    invoiceStatus: INVOICE_STATUS.ADJUSTMENT,
    // requestReleaseStatus: REQUEST_RELEASE_STATUS.ACCEPTED,
    permissions: {
      F1: [
        INVOICE_REQUEST_STATUS_PERMISSIONS.VIEW_PDF,
        INVOICE_REQUEST_STATUS_PERMISSIONS.RELEASE,
        INVOICE_REQUEST_STATUS_PERMISSIONS.SEND_EMAIL,
      ],
      F2: [
        INVOICE_REQUEST_STATUS_PERMISSIONS.VIEW_PDF,
        INVOICE_REQUEST_STATUS_PERMISSIONS.DUPLICATE,
        INVOICE_REQUEST_STATUS_PERMISSIONS.SEND_EMAIL,
      ],
    },
  },
  {
    label: 'REPLACED',
    value: 'REPLACED',
    variant: 'info',
    invoiceStatus: INVOICE_STATUS.REPLACED,
    // requestReleaseStatus: REQUEST_RELEASE_STATUS.ACCEPTED,
    permissions: {
      F1: [
        INVOICE_REQUEST_STATUS_PERMISSIONS.VIEW_PDF,
        INVOICE_REQUEST_STATUS_PERMISSIONS.SEND_EMAIL,
      ],
      F2: [
        INVOICE_REQUEST_STATUS_PERMISSIONS.VIEW_PDF,
        INVOICE_REQUEST_STATUS_PERMISSIONS.DUPLICATE,
        INVOICE_REQUEST_STATUS_PERMISSIONS.SEND_EMAIL,
      ],
    },
  },
  {
    label: 'REPLACEMENT',
    value: 'REPLACEMENT',
    variant: 'light-info',
    invoiceStatus: INVOICE_STATUS.REPLACEMENT,
    // requestReleaseStatus: REQUEST_RELEASE_STATUS.ACCEPTED,
    permissions: {
      F1: [
        INVOICE_REQUEST_STATUS_PERMISSIONS.VIEW_PDF,
        INVOICE_REQUEST_STATUS_PERMISSIONS.RELEASE,
        INVOICE_REQUEST_STATUS_PERMISSIONS.SEND_EMAIL,
      ],
      F2: [
        INVOICE_REQUEST_STATUS_PERMISSIONS.VIEW_PDF,
        INVOICE_REQUEST_STATUS_PERMISSIONS.DUPLICATE,
        INVOICE_REQUEST_STATUS_PERMISSIONS.SEND_EMAIL,
      ],
    },
  },
]
export const INVOICE_REQUEST_STATUS = arrayToObject(INVOICE_REQUEST_STATUS_OPTIONS)

// ANCHOR OLD
export const INVOICE_ACTIONS_HISTORY_TABLE_COLUMNS = [
  { label: 'orderNo', key: 'orderNo', style: 'width: 3em;' },
  { label: 'date', key: 'date' },
  { label: 'action', key: 'action' },
  { label: 'amount', key: 'amount' },
  { label: 'executor', key: 'executor' },
  { label: 'traceId', key: 'traceId' },
].filter(col => {
  if (col.key === 'traceId') return isADMF1.value
  return true
})
