<!-- NOTE: Component tự động chuyển là text-input / text-area tuỳ theo số lượng ký tự -->
<template lang="">
  <component
    :is="inputType"
    ref="refInput"
    :value="text"
    v-bind="[$attrs]"
    @input="val => $emit('update:text', val)"
  />
</template>
<script>
import {
  computed, nextTick, ref, watch,
} from '@vue/composition-api'
import { BFormInput, BFormTextarea } from 'bootstrap-vue'

export default {
  components: {
    BFormTextarea, BFormInput,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    lengthBreak: {
      type: Number,
      default: 20,
    },
    conditionBreak: {
      type: [Boolean, null],
      default: null,
    },
  },
  setup(props) {
    const refInput = ref()
    const inputType = computed(() => (props.conditionBreak ?? (props.text.length > props.lengthBreak)) ? 'BFormTextarea' : 'BFormInput')
    watch(inputType, () => {
      nextTick(() => refInput.value.focus())
    })
    return {
      refInput,
      inputType,
    }
  },
}
</script>
<style lang="">

</style>
