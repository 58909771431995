<template>
  <b-nav-item-dropdown
    id="notification-dropdown"
    ref="refNotificationDropdown"
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
    no-caret
  >
    <template #button-content>
      <feather-icon
        :badge="unReadCount"
        :badge-classes="`bg-danger badge-glow ${isMobileView ? 'font-small-1' : ''}`"
        class="text-body"
        icon="BellIcon"
        size="20"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex-center py-50">
        <h4 class="notification-title mb-0 mr-auto">
          Thông báo
        </h4>

        <div class="d-flex">
          <b-badge
            v-if="unReadCount"
            pill
            variant="light-primary"
            class="my-25 pl-75 mr-1 d-flex-center text-airline"
          >
            <span class="text-airline">
              {{ `${unReadCount} Chưa đọc` }}
            </span>
          </b-badge>

          <div
            v-if="notificationData.length"
            class="d-flex-center"
          >
            <b-dropdown
              id="notification-dropdown-header"
              v-ripple.400="'rgba(0, 207, 232, 0.15)'"
              class="custom_class rounded-pill p-50"
              variant="link"
              toggle-class="p-0"
              right
              no-caret
              boundary="viewport"
            >
              <template #button-content>
                <feather-icon
                  icon="MenuIcon"
                  size="21"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                id="notification-dropdown-header-item"
                :disabled="!unReadCount || isLoadingReadAll"
                @click="handleReadAllNoti(notificationData)"
              >
                <span class="align-middle ml-50">
                  Đánh dấu tất cả là đã đọc
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </li>

    <div class="vh-45">
      <div
        v-if="notificationData.length"
        class="vh-100 mt-25"
      >
        <div
          v-for="(notification, index) in notificationData"
          :key="index"
          class="card-noti d-flex"
        >
          <b-media
            class="py-50 d-flex aline-items-center flex-fill"
            @click="readNotiDetail(notification)"
          >
            <template #aside>
              <b-img
                class="rounded-circle p-25 ml-25 bg-light-primary"
                style="object-fit: contain"
                width="40"
                height="40"
                :src="appLogoImage"
                @error="val => onImgError(val)"
              />
            </template>

            <div class="d-flex-column">
              <p class="font-weight-bolder media-heading">
                {{ notification.subject }}
              </p>
              <small class="notification-text text-primary">
                <b>{{ convertISODateTime(notification.sendTime).dateTime.replace(' | ', ', ') }}</b>
              </small>
            </div>
          </b-media>

          <div class="d-flex-center pr-25">
            <b-dropdown
              id="notification-dropdown-item"
              v-ripple.400="'rgba(0, 207, 232, 0.15)'"
              class="rounded-pill p-50"
              variant="link"
              toggle-class="p-0"
              right
              no-caret
              boundary="viewport"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreHorizontalIcon"
                  size="16"
                  class="align-middle text-body"
                  @click="handleGetNotiById(notification.notificationId)"
                />
              </template>

              <b-dropdown-item
                id="notification-dropdown-item-item"
                :disabled="checkDisableReschedule(notification)"
                @click="handleToggleReSend(notification)"
              >
                <span class="align-middle ml-50">
                  {{ isReschedule ? 'Tắt cảnh báo' : 'Bật cảnh báo' }}
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>

        <infinite-loading @infinite="infiniteHandler">
          <div
            v-if="!notificationData.length"
            slot="spinner"
          />
          <div slot="no-more">
            Đã hiển thị toàn bộ thông báo
          </div>
        </infinite-loading>
      </div>

      <div
        v-else
        class="d-flex justify-content-center"
      >
        Không có thông báo
      </div>
    </div>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BDropdown,
  BDropdownItem,
  BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ref, computed, watch, onMounted,
} from '@vue/composition-api'
import { io } from 'socket.io-client'
import InfiniteLoading from 'vue-infinite-loading'
import Vue from 'vue'

import { getUserData } from '@/api/auth/utils'
import store from '@/store'
import router from '@/router'
import { env } from '@/libs/env'

import { convertISODateTime } from '@core/utils/filter'

import { $themeConfig } from '@themeConfig'

import notificationStoreModule from './notificationStoreModule'
import useNotificationHandle from './useNotificationHandle'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BImg,
    BDropdown,
    BDropdownItem,
    InfiniteLoading,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isDropdown2Visible: false,
      isDropdownHeaderVisible: false,
    }
  },
  watch: {
    $route(to) {
      if (to.name === 'apps-supports') {
        this.emitSupportRequestRoom(true)
      } else {
        this.emitSupportRequestRoom(false)
      }
    },
  },
  mounted() {
    this.$root.$on('bv::dropdown::show', bvEvent => {
      if (bvEvent.componentId === 'notification-dropdown-header') {
        this.isDropdownHeaderVisible = true
      }
    })
    this.$root.$on('bv::dropdown::hide', bvEvent => {
      if (bvEvent.componentId === 'notification-dropdown-header') {
        this.isDropdownHeaderVisible = false
      }
      if (this.isDropdownHeaderVisible) {
        bvEvent.preventDefault()
      }
    })

    this.$root.$on('bv::dropdown::show', bvEvent => {
      if (bvEvent.componentId === 'notification-dropdown-item') {
        this.isDropdown2Visible = true
      }
    })
    this.$root.$on('bv::dropdown::hide', bvEvent => {
      if (bvEvent.componentId === 'notification-dropdown-item') {
        this.isDropdown2Visible = false
      }
      if (this.isDropdown2Visible) {
        bvEvent.preventDefault()
      }
    })
  },
  setup(_, { root }) {
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const { appLogoImage: defaultLogo } = $themeConfig.app
    const appLogoInStore = computed(() => store.getters['app/getLogoNoti'])
    const appLogoImage = computed(() => appLogoInStore.value || env?.logoNoti || defaultLogo)
    const refNotificationDropdown = ref()
    const NOTIFICATION_APP_STORE_MODULE_NAME = 'app-notification'
    if (!store.hasModule(NOTIFICATION_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        NOTIFICATION_APP_STORE_MODULE_NAME,
        notificationStoreModule,
      )
    }
    // onUnmounted(() => {
    //   if (store.hasModule(NOTIFICATION_APP_STORE_MODULE_NAME)) {
    //     store.unregisterModule(NOTIFICATION_APP_STORE_MODULE_NAME)
    //   }
    // })
    // ========= !STORE ==============

    const {
      resendStop,
      resendRestart,
      getNotiById,
      readNoti,
      readAllNoti,
      unReadCountNoti,
      perfectScrollbarSettings,
    } = useNotificationHandle()
    const newNoti = ref()
    const checkBooking = ref()
    const notificationData = ref([])
    const loading = ref(true)
    const lastId = ref('')
    const size = ref(7)
    const unReadCount = ref(null)
    const isReschedule = ref(null)

    const loadNotificationData = async () => {
      try {
        const response = await store.dispatch('app-notification/getNoti', {
          lastId: lastId.value,
          recipientRefType: 'EMPLOYEE',
          channel: 'PUSH_NOTI',
          recipientRefId: getUserData().employeeData.id,
          size: size.value,
        })
        if (response.data.length) {
          notificationData.value.push(...response.data)
          lastId.value = response.data[response.data.length - 1].notificationId
        }
      } catch (error) {
        console.error({ error })
      } finally {
        loading.value = false
      }
    }

    function getUnRead() {
      unReadCountNoti().then(res => {
        unReadCount.value = res.count
      })
    }

    getUnRead()

    loadNotificationData()

    const socket = io(`${process.env.VUE_APP_SOCKET_NOTI}`, {
      path: process.env.VUE_APP_SOCKET_NOTI_PATH,
      query: {
        userId: getUserData().employeeData.id,
        isSupport: router.currentRoute.name === 'apps-supports' && isRoleF1.value,
      },
    })

    socket.on('supportRequestRoom', () => {
      root.$bvToast.toast('Có yêu cầu gọi tổng đài mới , vui lòng "làm mới" lại trang để kiểm tra', {
        title: 'Yêu cầu gọi tổng đài',
        noAutoHide: true,
        appendToast: true,
        variant: 'info',
        solid: true,
        bodyClass: 'font-medium-2 shadow-lg',
        headerClass: 'font-medium-2',
      })
    })

    socket.on('notification', data => {
      // Show noti quan trọng lên màn hình
      if (data?.isImportant) {
        Vue.$toast.warning(data.subject, {
          position: 'top-right',
          timeout: false,
          closeOnClick: false,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.1,
          showCloseButtonOnHover: true,
          hideProgressBar: false,
          closeButton: 'button',
          icon: true,
          rtl: false,

        })
      }
      newNoti.value = data
      checkBooking.value = data.data?.bookingId
      notificationData.value.unshift(data)
      getUnRead()
    })

    function emitSupportRequestRoom(isSupport) {
      socket.emit('supportRequestRoom', { isSupport: isSupport && isRoleF1.value })
    }

    const checkPermission = () => {
      if (!('serviceWorker' in navigator)) {
        throw new Error('No support for service worker!')
      }

      if (!('Notification' in window)) {
        throw new Error('No support for notification API')
      }

      if (!('PushManager' in window)) {
        throw new Error('No support for Push API')
      }
    }
    // FIXME to register service worker
    // const registerSW = async () => {
    //   const registration = await navigator.serviceWorker.register('sw.js')
    //   return registration
    // }

    const requestNotificationPermission = async () => {
      const permission = await Notification.requestPermission()
      if (permission !== 'granted') {
        throw new Error('Notification permission not granted')
      } else if (newNoti.value) {
        const message = newNoti.value?.content || 'Thông báo từ hệ thống'

        const notification = new Notification('Thông báo mới', {
          body: message,
          icon: env?.logoNoti,
        })

        notification.onclick = () => {
          window.focus()
          if (checkBooking.value) {
            router.push({ name: 'apps-reservations-modify', params: { id: checkBooking.value } })
          } else {
            router.push({ name: 'apps-flightsV2-page' })
          }
        }
      }
    }
    watch(() => newNoti.value, () => {
      requestNotificationPermission()
    }, { immediate: true })
    onMounted(() => {
      checkPermission()
      // registerSW()
    })

    function infiniteHandler($state) {
      if (notificationData.value.length) {
        store
          .dispatch('app-notification/getNoti', {
            lastId: notificationData.value[notificationData.value.length - 1].id,
            recipientRefType: 'EMPLOYEE',
            channel: 'PUSH_NOTI',
            recipientRefId: getUserData().employeeData.id,
            size: size.value,
          })
          .then(response => {
            notificationData.value.push(...response.data)
            $state.loaded()
            if (response.data.length === 0) {
              $state.complete()
            }
          })
          .catch(error => {
            console.error({ error })
          })
          .finally(() => {
            loading.value = false
          })
      }
    }

    function readNotiDetail(data) {
      if (data.data.bookingId) {
        readNoti(data.id)
          .then(() => {
            router.push({ name: 'apps-reservations-modify', params: { id: data.data.bookingId } })
            refNotificationDropdown.value.hide(true)
          })
      }
      if (data.data.ticketId) {
        readNoti(data.id)
          .then(() => {
            router.push({ name: 'apps-detail-resell-ticket', params: { id: data.data.ticketId } })
            refNotificationDropdown.value.hide(true)
          })
      }
      // this.getUnRead()
    }

    function handleGetNotiById(id) {
      getNotiById(id)
        .then(res => {
          isReschedule.value = res.isReschedule
        })
    }

    const isLoadingReadAll = ref(false)

    function handleReadAllNoti() {
      isLoadingReadAll.value = true
      readAllNoti()
        .then(() => {
          unReadCount.value = 0
          getUnRead()
        })
        .finally(() => {
          isLoadingReadAll.value = false
        })
    }

    async function handleToggleReSend(noti) {
      if (!noti || !noti.notificationId) return
      const id = noti.notificationId
      const getNotiData = await getNotiById(id)
      if (getNotiData) {
        if (getNotiData.isReschedule) {
          resendStop(id)
        } else {
          resendRestart(id)
        }
      }
    }

    const isDeadlineExpired = (currentTime, deadline) => {
      const currentTimeObject = new Date(currentTime)
      const deadlineObject = new Date(deadline)
      if (Number.isNaN(currentTimeObject.getTime()) || Number.isNaN(deadlineObject.getTime())) {
        console.error('Invalid date string')
        return true
      }
      return deadlineObject < currentTimeObject // true: quá hạn
    }

    const checkDisableReschedule = noti => {
      if (noti.sendType !== 'SCHEDULE') {
        return true
      }

      const currentTime = new Date()?.toISOString()
      const deadline = convertISODateTime(noti.deadline).ISOdatetime

      return isDeadlineExpired(currentTime, deadline)
    }

    function onImgError(val) {
      const currentSource = val.srcElement.currentSrc
      val.srcElement.onerror = null
      val.srcElement.src = appLogoInStore.value && (currentSource !== appLogoInStore.value) ? appLogoInStore.value : defaultLogo
    }

    return {
      appLogoImage,
      perfectScrollbarSettings,
      notificationData,
      socket,
      infiniteHandler,
      lastId,
      convertISODateTime,
      unReadCount,
      handleGetNotiById,
      readNotiDetail,
      isReschedule,
      checkDisableReschedule,
      handleToggleReSend,
      isLoadingReadAll,
      handleReadAllNoti,
      refNotificationDropdown,
      onImgError,
      emitSupportRequestRoom,
    }
  },
}
</script>

<style scoped lang="scss">
.vh-45 {
  height: 45vh;
  overflow: auto;
}

.card-noti {
  cursor: pointer;
  /* padding: 3px 0; */
}

.card-noti:hover {
  background: #ececec;
}

#notification-dropdown ::v-deep {
  .badge.badge-up.badge-pill.bg-danger.badge-glow {
    top: -8px;

    @media (max-width: 576px) {
      top: -11px;
    }
  }

}

.custom_class ::v-deep {
  .dropdown-menu {
    top: 25px !important;
}
}
</style>

<style lang="scss">
  .Vue-Toastification__close-button.show-on-hover {
    opacity: 1 !important;
    color: black;
  }
  .b-toast {
  top:55px
  }
</style>
